@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.root {
  height: var(--sw-input-height);
  position: relative;
}

.button {
  appearance: none;
  font-weight: var(--font-weight-bold);
  width: 100%;
  border-width: 0;
  height: 100%;
  border-radius: var(--border-radius);
  cursor: pointer;
  user-select: none;
  padding: 0 20px;
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out,
    transform 0.1s ease-in-out, background-color 0.1s ease-in-out;

  &:active {
    transform: scale(0.975);
  }

  &:focus {
    outline: 0;
    box-shadow: var(--sw-primary-outline-box-shadow);
  }
}

.text {
  font-size: var(--font-size-15px, 15px);
  line-height: var(--line-height-for-15px);
  font-weight: var(--font-weight-bold);
}

.iconContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  visibility: hidden;
}

.loadingIcon {
  width: 16px;
  height: 16px;
  margin: auto;
  border: 3px solid transparent;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
  display: none;
}

.fallback {
  display: flex;
  height: var(--height);
}

.fallbackContent {
  display: flex;
  align-items: center;
}

.fallbackIcon {
  width: 58px;
  border: 1px solid red;
}

// Modifiers
.root {
  // Display
  &.displayBlock {
    display: block;
  }

  &.displayInlineBlock {
    display: inline-block;
  }

  // Disabled
  &.disabled,
  &.loading {
    .button {
      cursor: default;
      opacity: 0.75;
      pointer-events: none;
      box-shadow: none;
    }

    .text,
    .loadingIcon {
      opacity: 0.8;
    }
  }

  // Loading
  &.loading {
    .button {
      transform: scale(0.975);
    }

    .text {
      visibility: hidden;
    }

    .iconContainer {
      visibility: visible;

      .loadingIcon {
        display: block;
      }
    }
  }

  // Icon
  &.hasIcon {
    width: 42px;

    .iconContainer {
      visibility: visible;
    }

    .icon {
      width: 14px;
      height: 14px;
      display: flex;
      margin: auto;
    }

    .text {
      margin-left: 20px;
    }

    .svgIcon {
      fill: var(--grey-icon-color);
    }
  }

  &.hasIcon.hasText {
    width: auto;

    .icon {
      margin-left: 16px;
    }
  }

  // Rounded
  &.rounded {
    .button {
      border-radius: 42px;
    }

    .fallback {
      border-radius: 42px !important;
    }
  }

  // Subtle
  &.subtle {
    &.variantPrimary {
      .button {
        background-color: var(--subtle-color);
        color: var(--sw-primary);

        &:hover {
          background-color: var(--subtle-dark-color) !important;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .loadingIcon {
        border-top-color: var(--primary-color);
      }

      .svgIcon {
        fill: var(--subtle-background-text-color);
      }
    }

    &.variantSecondary {
      .button {
        background-color: var(--subtle-color);
        color: var(--secondary-color);

        &:active {
          background-color: var(--subtle-darker-color) !important;
        }
      }

      .loadingIcon {
        border-top-color: var(--secondary-color);
      }

      .svgIcon {
        fill: var(--secondary-color);
      }
    }

    &.variantSuccess {
      .button {
        background-color: var(--subtle-color);
        color: var(--success-color);

        &:active {
          background-color: var(--subtle-darker-color) !important;
        }
      }

      .loadingIcon {
        border-top-color: var(--success-color);
      }

      .svgIcon {
        fill: var(--success-color);
      }
    }

    &.variantDanger {
      .button {
        background-color: var(--subtle-color);
        color: var(--danger-color);

        &:active {
          background-color: var(--subtle-darker-color) !important;
        }
      }

      .loadingIcon {
        border-top-color: var(--danger-color);
      }

      .svgIcon {
        fill: var(--danger-color);
      }
    }

    &.variantWarning {
      .button {
        background-color: var(--subtle-color);
        color: var(--warning-color);

        &:active {
          background-color: var(--subtle-darker-color) !important;
        }
      }

      .loadingIcon {
        border-top-color: var(--warning-color);
      }

      .svgIcon {
        fill: var(--warning-color);
      }
    }

    &.variantInfo {
      .button {
        background-color: var(--subtle-color);
        color: var(--info-color);

        &:active {
          background-color: var(--subtle-darker-color) !important;
        }
      }

      .loadingIcon {
        border-top-color: var(--info-color);
      }

      .svgIcon {
        fill: var(--info-color);
      }
    }
  }

  // Variant
  &.variantPrimary {
    .button {
      background-color: var(--sw-primary);
      color: var(--sw-primary-bg-text);

      &:hover {
        background-color: var(--sw-primary-light);
      }
    }

    .loadingIcon {
      border-top-color: var(--sw-primary-bg-text);
    }

    .svgIcon {
      fill: var(--sw-primary-bg-text);
    }
  }

  &.variantSecondary {
    .button {
      background-color: var(--secondary-color);
      color: var(--secondary-background-text-color);

      &:active {
        background-color: var(--secondary-lighter-color);
      }
    }

    .loadingIcon {
      border-top-color: var(--secondary-background-text-color);
    }

    .svgIcon {
      fill: var(--secondary-background-text-color);
    }
  }

  &.variantSuccess {
    .button {
      background-color: var(--success-color);
      color: var(--success-background-text-color);

      &:active {
        background-color: var(--success-lighter-color);
      }
    }

    .loadingIcon {
      border-top-color: var(--success-background-text-color);
    }

    .svgIcon {
      fill: var(--success-background-text-color);
    }
  }

  &.variantDanger {
    .button {
      background-color: var(--danger-color);
      color: var(--danger-background-text-color);

      &:active {
        background-color: var(--danger-lighter-color);
      }
    }

    .loadingIcon {
      border-top-color: var(--danger-background-text-color);
    }

    .svgIcon {
      fill: var(--danger-background-text-color);
    }
  }

  &.variantWarning {
    .button {
      background-color: var(--warning-color);
      color: var(--warning-background-text-color);

      &:active {
        background-color: var(--warning-lighter-color);
      }
    }

    .loadingIcon {
      border-top-color: var(--warning-background-text-color);
    }

    .svgIcon {
      fill: var(--warning-background-text-color);
    }
  }

  &.variantInfo {
    .button {
      background-color: var(--info-color);
      color: var(--info-background-text-color);

      &:active {
        background-color: var(--info-lighter-color);
      }
    }

    .loadingIcon {
      border-top-color: var(--info-background-text-color);
    }

    .svgIcon {
      fill: var(--info-background-text-color);
    }
  }

  &.outlined {
    .button {
      background-color: var(--page-background-color);
    }

    &.variantPrimary {
      .button {
        border: 1px solid var(--primary-button-outlined-border-color);
        color: var(--sw-primary);

        &:active {
          background-color: var(--primary-pale-color) !important;
        }
      }

      .svgIcon {
        fill: var(--sw-primary);
      }
    }

    &.variantSecondary {
      .button {
        border: 1px solid var(--secondary-color);
        color: var(--secondary-color);

        &:active {
          background-color: var(--secondary-pale-color) !important;
        }
      }
    }

    &.variantSuccess {
      .button {
        border: 1px solid var(--success-color);
        color: var(--success-color);

        &:active {
          background-color: var(--success-pale-color) !important;
        }
      }
    }

    &.variantDanger {
      .button {
        border: 1px solid var(--danger-color);
        color: var(--danger-color);

        &:active {
          background-color: var(--danger-pale-color) !important;
        }
      }
    }

    &.variantInfo {
      .button {
        border: 1px solid var(--info-color);
        color: var(--info-color);

        &:active {
          background-color: var(--info-pale-color) !important;
        }
      }
    }

    &.variantWarning {
      .button {
        border: 1px solid var(--warning-color);
        color: var(--warning-color);

        &:active {
          background-color: var(--warning-pale-color) !important;
        }
      }
    }

    &.variantNeutral {
      .button {
        border: 1px solid var(--neutral-color);
        color: var(--neutral-color);

        &:active {
          background-color: var(--neutral-pale-color) !important;
        }
      }
    }
  }
}
